import React from "react";
import logo from "./tilialabs-logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <div className="Title">
          <p className="App-sub-header1">
            Marks Re:Envisioned
            <br />
            <span className="App-sub-header2">Are. You. Ready?</span>
          </p>
          {/* <p className="App-sub-header2">Are. You. Ready?</p> */}
        </div>
        <p className="App-sub-header3">November 2, 2021 8:10 AM EST</p>
      </header>
    </div>
  );
}

export default App;
